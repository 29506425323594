@import 'swiper/swiper.min.css';
@import 'swiper/modules/pagination/pagination.min.css';
/* @import 'swiper/modules/scrollbar/scrollbar.min.css'; */
@import 'swiper/modules/navigation/navigation.min.css';

/* leto font import */
@font-face {
  font-family: LatoFontBlack;
  src: url('./assets/fonts/Lato-Black.ttf');
}
@font-face {
  font-family: LatoFontBold;
  src: url('./assets/fonts/Lato-Bold.ttf');
}
@font-face {
  font-family: LatoFontRegular;
  src: url('./assets/fonts/Lato-Regular.ttf');
}

body {
  margin: 0;
  font-family: 'Kumbh Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', 'kumbh', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
.MuiSelect-select,
.MuiMenuItem-root {
  font-family: 'Kumbh Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', 'kumbh', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* leto font family */
.leto {
  font-family: LatoFontBlack !important;
}
.leto-bold {
  font-family: LatoFontBold !important;
}
.leto-reg {
  font-family: LatoFontRegular !important;
}
.leto input,
.otp-wrapper input {
  font-family: LatoFontRegular !important;
}
img {
  width: 100%;
  height: 100%;
}

.thm-blue {
  color: #15716b;
}

.grey-color {
  color: #8a8383;
}
.font-bold {
  font-weight: bold !important;
}

.font-bolder {
  font-weight: bolder !important;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600 !important;
}

.font-700 {
  font-weight: 700 !important;
}

.font-400 {
  font-weight: 400;
}

.font-normal {
  font-weight: normal;
}

.font-size-12px {
  font-size: 12px;
}
.font-size-13px {
  font-size: 13px;
}
.font-size-14px {
  font-size: 14px !important;
}

.font-size-16px {
  font-size: 16px;
}

.font-size-20px {
  font-size: 20px;
}

.font-size-24px {
  font-size: 24px;
}

.normal-input {
  border-radius: 5px;
  height: 2.5rem;
  width: 100%;
  padding: 0 10px;
}
.normal-text-area-input {
  border-radius: 5px;
  width: 100%;
  padding: 0 10px;
}
.text-start {
  text-align: start;
}

.text-decoration-none {
  text-decoration: none;
}

.text-underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.w-10 {
  width: 10%;
}

.w-30 {
  width: 30%;
}

.word-break {
  word-break: break-word;
}

.img-auto {
  width: auto !important;
  height: auto !important;
}

.text-center {
  text-align: center;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.orWrapper {
  display: flex;
  flex-direction: row;
}

.orWrapper:before {
  content: '';
  flex: 1 1;
  border-image-source: linear-gradient(to left, #ffffff, #000000);
  height: 2px;
  background: linear-gradient(
    90deg,
    rgb(0 0 0 / 42%) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  margin: auto;
}
.orWrapper:after {
  content: '';
  flex: 1 1;
  border-image-source: linear-gradient(to left, #ffffff, #000000);
  height: 2px;
  background: linear-gradient(
    -1deg,
    rgb(0 0 0 / 42%) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  margin: auto;
}

.orUpgradeWrapper:before,
.orUpgradeWrapper:after {
  content: '';
  flex: 1 1;
  border-bottom: 1px solid;
  background-color: linear-gradient(
    270deg,
    #000000 2.71%,
    rgba(0, 0, 0, 0) 103.24%
  );
  margin: auto;
}

.orWrapper:before {
  margin-right: 60px;
}

.orWrapper:after {
  margin-left: 60px;
}
.home-component .orWrapper::before,
.home-component .orWrapper::after {
  background: none;
  border-image-source: unset;
}
.appTabs {
  background: #f5f5f5;
  border: 1px solid #acacac;
  padding: 2px 20px 2px 10px;
  border-radius: 10px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.appTabs .Mui-selected {
  background: #002c29;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 9px;
  color: white !important;
  font-weight: 600;
  font-size: 12px;
}

.appTabs .MuiTab-root {
  min-width: 130px;
}

.select-type {
  border: #acacac solid 1.5px;
  border-radius: 6px !important;
  max-height: 2.8rem !important;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.select-custom-arrow {
  -webkit-appearance: none;
  appearance: none;
  background-image: url('./assets/images/sellBike/down-arrow1.png');
  background-position: 96%;
  background-repeat: no-repeat;
}

.select-custom-arrow .MuiSelect-icon,
.sort-by-dropdown .MuiSelect-icon {
  display: none;
}
/* mui dropdown css */
.MuiMenu-list {
  padding: 0 !important;
}
.Mui-selected:hover {
  background-color: rgb(233 233 233) !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover {
  background-color: rgb(185 185 185 / 12%) !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: rgb(137 137 137 / 8%) !important;
}
.select-normal-input {
  height: 1rem;
}

.select-custom-arrow:focus {
  background-image: url('./assets/images/sellBike/down_arrow.png');
}
.select-custom-arrow [disabled] {
  opacity: 0.8;
}

.select-custom-MuiMenu-paper {
  max-height: 300px !important;
}

.text-overlap {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1350px !important;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px !important;
  }
}

.defaultChip {
  padding: 15px !important;
  /* border: 1px solid #8a8383 !important; */
  border: 1px solid #8a8383 !important;
  border-radius: 25px !important;
  background: white !important;
  /* background: #e9e9e9 !important; */
  /* color: #8a8383 !important; */
  color: #8a8383 !important;
  margin-right: 10px !important;
}

/* date picker css */
.datePiker {
  background-color: white;
  border: 1px solid rgb(118, 118, 118);
  padding: 0.3em 1.6em 0 1.6em;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  height: 2.5rem;
  width: 100%;
  padding: 5px;
  cursor: pointer;
  align-items: center;
  padding-left: 10px;
}
.datePiker svg {
  fill: #15716b;
}
.text-input {
  border: 1px solid rgb(118, 118, 118);
}
.text-input::placeholder {
  color: #212529;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #15716b !important;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #f0f0f0 !important;
}

/* date picker css */

.selectedChip {
  padding: 15px !important;
  background: #15716b !important;
  border: 1px solid #15716b !important;
  border-radius: 25px !important;
  color: #fff !important;
  margin-right: 10px !important;
}
.otp-wrapper div:first-child {
  justify-content: center;
}

.splashLoader {
  margin: 300px 0px 300px 0px;
  text-align: center;
}

@media (max-width: 540px) {
  .defaultChip,
  .selectedChip {
    padding: 1.5px !important;
    height: 25px;
    font-size: 0.6125rem;
  }
  .orWrapper:before {
    margin-right: 20px;
  }

  .orWrapper:after {
    margin-left: 20px;
  }
}

.f-25 {
  font-size: 25px;
}
.f-30 {
  font-size: 30px;
}

.pt-10px {
  padding-top: 1.5rem !important;
}
.icon-height {
  height: 30px !important;
}
.mt-3rem {
  margin-top: 3rem;
}
.dh-30 {
  height: 30px !important;
}
/* mobile common css */
@media (max-width: 576px) {
  .mt-3rem {
    margin-top: 20px;
  }
  html {
    overflow-x: hidden;
  }
  .sellBike-recommdation .defaultChip,
  .sellBike-recommdation .selectedChip {
    padding: 0px !important;
  }
  .m-width {
    max-width: 200px;
  }
  .f-16 {
    font-size: 16px !important;
  }
  .f-13 {
    font-size: 13px !important;
  }
  .f-14 {
    font-size: 14px !important;
  }
  .f-12 {
    font-size: 12px !important;
  }
  .f-11 {
    font-size: 11px !important;
  }
  .f-18 {
    font-size: 18px !important;
  }
  .f-20 {
    font-size: 20px !important;
  }
  .f-10 {
    font-size: 10px !important;
  }
  .w-10 {
    width: 10px !important;
  }
  #hi-auto {
    height: auto !important;
  }
  .m-space {
    justify-content: flex-start !important;
    padding-left: 23px !important;
  }
  .mr-05 {
    margin: 0.5rem 0;
  }
  .mb-5px {
    margin-bottom: 5px;
  }
  .pt-10px {
    padding-top: 10px !important;
  }
  .pt-0px {
    padding-top: 0px !important;
  }
  .m-color {
    width: 20% !important;
    padding: 0px 0px !important;
  }
  .icon-height {
    height: 13px !important;
  }
  .bikeSlider .swiper {
    margin: 0px 18px;
  }
  .bike-perticular img.you-might {
    height: 103px !important;
  }
  .mt-3px {
    margin-top: 3px;
  }
  .m-center {
    text-align: center;
  }
  .m-center img {
    max-width: 150px;
    width: 100%;
  }
  .h-auto-m {
    height: auto !important;
  }
  .w-full {
    width: 100% !important;
  }
  .w-220px {
    max-width: 220px;
    width: 100%;
  }
  .react-datepicker__input-container .datePiker,
  .select-custom-arrow {
    font-size: 14px;
  }
  .left-side-section .error {
    font-size: 14px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  .m-justifty-space {
    justify-content: space-between;
  }
  .mt-5 {
    margin-top: 5px;
  }
  .pl-pr-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-top: 10px;
  }
  .h-55 {
    height: 55px;
  }
  .m-h-29px {
    min-height: 29px;
  }
  .m-h-39px {
    min-height: 27px;
  }
  .m-p-0 {
    padding: 2px 0px 0px 3px !important;
  }
  #m-mt-1 {
    margin-top: 1rem !important;
  }
  .m-hide {
    display: none !important;
  }
}
@media (min-width: 576px) {
  .ml-15 {
    margin-left: 15px;
  }
  .mr-15 {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 960px) {
  .ipad-spacing {
    padding-left: 60px !important;
  }
  .img-auto {
    max-width: 200px !important;
  }
}
@media only screen and (max-width: 770px) and (min-width: 765px) {
  .search-btn-wrap {
    margin-right: 20px;
  }
}

@media (min-width: 1366px) {
  .amt-right {
    text-align: right;
  }
}

.swiper-button-next {
  display: none !important;
}

.swiper-button-prev {
  display: none !important;
}

fieldset {
  border-style: none !important;
}

.Mui-disabled {
  opacity: 0.5;
}

.error {
  color: red;
}

input[type='radio'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #15716b;
  border-radius: 50%;
  outline: none;
  margin-right: 5px;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
.MuiBreadcrumbs-separator {
  margin-left: 2px !important;
  margin-right: 2px !important;
}
input[type='radio']:checked:before {
  background: #15716b;
}

::-webkit-calendar-picker-indicator {
  filter: invert(31%) sepia(92%) saturate(403%) hue-rotate(127deg)
    brightness(94%) contrast(87%);
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
#phone_number {
  padding: 18px 12px 18px;
  font-family: LatoFontRegular;
}
/* Provisional Quote css */
.price-range {
  font-size: 30px;
  font-weight: 700;
}
.seperator {
  padding-left: 5px;
  display: inline-block;
  padding-right: 5px;
}
/* buy bike sort by dropdown*/
.sort-by-dropdown {
  -webkit-appearance: none;
  appearance: none;
  background-image: url('./assets/images/sellBike/down-arrow1.png');
  background-position: 95%;
  background-repeat: no-repeat;
}

.sort-by-dropdown:focus {
  background-image: url('./assets/images/sellBike/down_arrow.png');
  background-repeat: no-repeat;
  background-position: 95%;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  appearance: none;
}
.sort-by-dropdown [disabled] {
  opacity: 0.8;
}
.bor-bottom {
  border-bottom: 1px solid #15716b !important;
  opacity: 1;
}
.search-btn-wrap .MuiButtonBase-root.Mui-disabled {
  background: #8a8383;
}
.down-arrow {
  width: 8px;
  margin-left: 10px;
}

/* custom scroll bar */
#customScroll::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #e9e9e9;
}
#customScroll::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #e9e9e9;
}
#customScroll::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #acacac;
}

.searchSugg {
  position: absolute;
  top: 45px;
  left: 0px;
  right: 0px;
  z-index: 10000;
  width: 100%;
  max-height: 180px;
  overflow: auto;
  box-shadow: 0px 10px 16px 0 rgba(0, 0, 0, 0.22) !important;
  overflow-x: hidden;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* find by bike arrows */
.h-30 {
  height: 30px !important;
}
.arrow-prev {
  background-color: #fff;
  padding: 8px 13px;
  height: 38px !important;
  border-radius: 50%;
  margin: 0 auto;
  transform: rotate(180deg);
  width: 38px;
}
.arrow-next {
  background-color: #fff;
  padding: 8px 13px;
  height: 38px !important;
  border-radius: 50%;
  margin-right: 13px;
  width: 38px;
}
.about-screen .indicator {
  display: none;
}
.about-screen .Mui-selected::after {
  content: '';
  height: 3px;
  width: 48px;
  position: absolute;
  border-radius: 20px;
  bottom: 0px;
  background: linear-gradient(
    306.62deg,
    rgb(3, 81, 77) 1.21%,
    rgb(44, 169, 152) 94.64%
  );
}

.disabled_button {
  background: #8a8383 !important;
}
.disabled_link {
  color: #8a8383 !important;
}
.me-md-5 a,
.proCard a {
  text-decoration: none;
}
.h-20px {
  min-height: 19px;
}

.react-datepicker__calendar-icon {
  right: 0;
  width: 2.2rem !important;
  height: 2.2rem !important;
  filter: invert(33%) sepia(12%) saturate(3361%) hue-rotate(128deg)
    brightness(96%) contrast(84%);
}
.react-datepicker__view-calendar-icon input {
  width: 100%;
  border: #acacac solid 1.5px;
  height: 2.5rem;
  border-radius: 5px;
  padding-left: 10px !important;
}

/* safari browser css start */
@media not all and (min-resolution: 0.001dpcm) {
  .orWrapper:before {
    border-image-source: unset;
  }
  .orWrapper:after {
    border-image-source: unset;
  }
}
/* safari browser css end*/
.w-135 {
  width: 135px !important;
}
.mobile-store-slider {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}
.mobile-store-slider .MuiCardContent-root:last-child {
  padding-bottom: 10px;
}
